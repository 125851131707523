import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "panel_overlay_background", "panel_overlay" ]

  connect() {
  
  }

  show_overlay() {
    this.panel_overlayTarget.style.display = "block"
    this.panel_overlay_backgroundTarget.style.display = "block"
    console.log('hide')
  }
  hide_overlay() {
    this.panel_overlayTarget.style.display = "none"
    this.panel_overlay_backgroundTarget.style.display = "none"
    console.log('show')
  }

}
